import React, { Suspense } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import NumberFormat from "react-number-format";
//import ShippingOptionForm from "./ShippingOptionForm";
//import PaymentOptionForm from "./PaymentOptionForm";
//import CreditDetailsForm from "./CreditDetailsForm";
//import StripeForm from "./CreditDetailsForm/StripeForm";
//import SummaryForm from "./Summary";
import APILoader from 'Components/MainScreen/AppBody/APILoader';
// import ValidSMSModal from 'Components/MainScreen/AppBody/ValidSMSModal';
// import MakeOrderErrorModal from "./MakeOrderErrorModal";
// import ThanksModal from "../ThanksModal";
// import ThanksCouponModal from "../ThanksCouponModal";
// import CardNumberModal from "./CardNumberModal";
// import MapModal from "./MapModal";
// import ReactPixel from 'react-facebook-pixel';
const ShippingOptionForm = React.lazy(() => import('./ShippingOptionForm'));
const SummaryForm = React.lazy(() => import('./Summary'));
const StripeForm = React.lazy(() => import('./CreditDetailsForm/StripeForm'));
const CreditDetailsForm = React.lazy(() => import('./CreditDetailsForm'));
const PaymentOptionForm = React.lazy(() => import('./PaymentOptionForm'));
//const APILoader = React.lazy(() => import('Components/MainScreen/AppBody/APILoader'));
const ValidSMSModal = React.lazy(() => import('Components/MainScreen/AppBody/ValidSMSModal'));
const MakeOrderErrorModal = React.lazy(() => import('./MakeOrderErrorModal'));
const ThanksModal = React.lazy(() => import('../ThanksModal'));
const ThanksCouponModal = React.lazy(() => import('../ThanksCouponModal'));
const CardNumberModal = React.lazy(() => import('./CardNumberModal'));
const MultipassEnterAmountModal = React.lazy(() => import('./MultipassEnterAmountModal'));
const MapModal = React.lazy(() => import('./MapModal'));
const ReactPixel = React.lazy(() => import('react-facebook-pixel'));
@inject((store) => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class Payment extends React.Component {
    constructor(props) {
        super(props)
        const { orderData, otherPaymentType, paymentProcessing } = this.props.myShopApiStore;
        
        this.state = {
            isShippingOption: !orderData || orderData.isDelivery,
            currentStep:0, // paymentProcessing ? this.checkoutProcess.findIndex(step => step.name === 'summary') : 0,
            //  isPaymentForm: false
            smsModalIsOpen: false,
            thanksModalIsOpen: false,
            termsModalIsOpen: false,
            apiLoaderIsOpen: false,
            makeOrderErrorModalIsOpen: false,
            makeOrderErrorModal: { code: '', message: '' },
            cardNumberModalIsOpen: false,
            multipassEnterAmountModalIsOpen: false,
            mapModalIsOpen: false,
            stepsHistory: [],
            orderStatus: 'init', // success|failure
        }
    }

    checkoutProcess = [
        { title: 'Shipping Option', name: 'shipping', view: ShippingOptionForm, nextName: () => 'payment_options' },
        { title: 'Payment Option', name: 'payment_options', view: PaymentOptionForm, nextName: async () => 
            !await this.isUserRegistred() || this.props.isWrongSMSCode ? 'payment_options' : (this.state.isShippingOption ? 'location': 'summary') 
        },
        { title: 'Approve location', name: 'location', modal: 'mapModalIsOpen', nextName: () => 'summary'},
        { title: 'Summary', name: 'summary', view: SummaryForm, 
            nextName: async () => {
                const { paymentProcessing, orderData, otherPaymentType, shop,setCreditUniqueId  } = this.props.myShopApiStore;
               
                switch (true) {
                    case this.state.orderStatus === 'success':
                        this.stopProcessing();
                        setCreditUniqueId('');
                        return null;
                    case !paymentProcessing && (new URLSearchParams(window.location.search)).get('payment_intent'):
                        this.stopProcessing();
                        setCreditUniqueId('');
                        return null;
                    case this.props.isWrongSMSCode:
                        return 'payment_options';
                    case (otherPaymentType == 'cash' || otherPaymentType == 'other') :
                        const runPreOrder = await this.isPreOrderCreated();
                        if(runPreOrder) {
                            return !(await this.isMakeOrderSuccess()) ? 'summary' : 'summary';
                        } else {
                            return 'summary';
                        }
                    case (orderData.isCredit && shop.paymentSystem != 'stripe') || (orderData.isCredit && shop.paymentSystem === 'stripe' && !paymentProcessing):
                        return !(await this.isPreOrderCreated()) ? 'summary' :
                            (shop.paymentSystem === 'stripe' ? 'stripe' : 'credit')
                    case !!otherPaymentType && !paymentProcessing && otherPaymentType !== 'budget':
                        return 'cardNumber';
                    //case (otherPaymentType == 'multipass' || otherPaymentType == 'cibus' || otherPaymentType == 'cibus') && paymentProcessing :
                        
                    default:
                        return !(await this.isMakeOrderSuccess()) ? 'summary' : 'summary';
                }
            }
        },
        { title: 'Card Number', name: 'cardNumber', modal: 'cardNumberModalIsOpen', nextName: () => 'summary' },
        { title: 'Credit Details', name: 'credit', view: CreditDetailsForm, 
            nextName: async () => !(await this.isMakeOrderSuccess()) ? 'summary' : 'summary' 
        },
        { title: 'Stripe', name: 'stripe', view: StripeForm, nextName: () => 'summary'}
    ];

    stopProcessing = () => {
        const { setPaymentProcessing, setCreditParams, setCreditUniqueId, clearReadyToOrderCartItems,
            clearBlockClosing, clearCoupon, setSessionUrl } = this.props.myShopApiStore;
        setPaymentProcessing(false);
        setCreditParams('token', '');
        // setCreditUniqueId('');
        setSessionUrl('');
        const search = new URLSearchParams(window.location.search);
        search.has('payment_intent') && window.history.replaceState(null, '', window.location.pathname);
        
        this.props.ifaceMngr.setActiveTopMenu('ShopMenu');
        if (this.state.orderStatus === 'success') {
            clearReadyToOrderCartItems();
            clearBlockClosing();
            clearCoupon();
        }
    }

    tryAnotherCard = (message = 'Payment failed. Try another card.') => {
        this.props.myShopApiStore.setPaymentProcessing(false);
        this.setState(prevState => ({ ...prevState, currentStep: this.checkoutProcess.findIndex(step => step.name === 'summary')}));
        if (message) this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code: 'PAYMENT_FAILED', message }});
    }

    updateOrderStatus = (key, value) => {
        this.setState(prevState => ({
            [key]: value
        }));
    };
    
    nextStep = () => {
        
        const defaultNext = async () => {
            const nextName = await this.checkoutProcess[this.state.currentStep].nextName();

            if (!nextName) {
                this.stopProcessing();
                this.setCreditUniqueId('');
                return;
            }

            const nextStep = this.checkoutProcess.findIndex(step => step.name === nextName);
            const modal = this.checkoutProcess[nextStep].modal ?? false;
            if (this.checkoutProcess[this.state.currentStep].name !== "cardNumber" && this.checkoutProcess[this.state.currentStep].name !== "location" && this.checkoutProcess[this.state.currentStep].view) {
                this.setState(prevState => ({
                    ...prevState,
                    stepsHistory: [...prevState.stepsHistory, prevState.currentStep]
                }));
            }
            this.setState(prevState => ({ ...prevState, currentStep: nextStep , ...( modal ? {[modal]: true }:{}) }));
        }
                
        this.checkoutProcess[this.state.currentStep].nextStep && this.checkoutProcess[this.state.currentStep].nextStep();
        defaultNext();
    }

    prevStep = () => {
        if(this.state.currentStep > 0){
            const history = [...this.state.stepsHistory];
            const prevStep = history.pop();
            if (typeof prevStep === 'undefined' || prevStep < 0 || prevStep > this.checkoutProcess.length - 1) {
                window.history.replaceState(null, document.title, window.location.pathname);
                return;
            }
            this.setState(prevState => ({...prevState, currentStep: prevStep , stepsHistory: history}));
            if(this.checkoutProcess[prevStep].name === 'summary') {
                const { setOrderData } = this.props.myShopApiStore;
                setOrderData("isCredit", false);
            }

            //reset date,from,to of deliveryData & pickupData---------------
            if(this.checkoutProcess[prevStep].name === 'shipping'){
                const { orderData, setOrderData } = this.props.myShopApiStore;
                const { deliveryData, pickupData } = orderData;
                setOrderData('deliveryData', {...deliveryData, date: '', from: '', to: ''})
                setOrderData('pickupData', {...pickupData, date: '', from: '', to: ''})
            }
            //-----
            
        }
    }

    componentDidMount = async () => {
        const { paymentProcessing, creditParams, setCreditUniqueId, clearReadyToOrderCartItems, clearBlockClosing, clearCoupon } = this.props.myShopApiStore;
        if (paymentProcessing) {
            this.props.myShopApiStore.setOtherPaymentType('')
            this.props.myShopApiStore.setCardNumber('')
            
            const search = new URLSearchParams(window.location.search);

            if (creditParams.token !== search.get('payment_intent_client_secret')) {
                this.setState(prevState => ({ ...prevState, orderStatus: 'failure' }));
                this.stopProcessing();
                return;
            } else if (creditParams.token == search.get('payment_intent_client_secret') && search.get('redirect_status') === 'succeeded') {
                this.setState({apiLoaderIsOpen: true});

                setTimeout(() => {
                    this.setState({apiLoaderIsOpen: false});
                }, 450);

                setTimeout(() => {
                    this.setState({ thanksModalIsOpen: true });
                }, 451);
                
                // return;
            }
            
            this.setState(prevState => ({ ...prevState, currentStep: this.checkoutProcess.findIndex(step => step.name === 'summary')}));
            (async () => search.get('redirect_status') !== 'succeeded' ? 'summary' :
                (await this.checkoutProcess.find(step => step.name === 'summary').nextName())
            )()
                .then((stepName) =>        
                    this.setState(prevState => ({...prevState, 
                        currentStep: this.checkoutProcess.findIndex(step => step.name === stepName)}))
                );
           
        }

        setTimeout(() => {
            let PriceInfoHeight = document.querySelector(`.${style.PriceInfo}`);        
            if (PriceInfoHeight && PriceInfoHeight.offsetHeight > 0) {
                document.documentElement.style.setProperty('--PriceInfoHeight', `${PriceInfoHeight.offsetHeight}px`);
            }
        }, 200)
        
        const checkDuration = 6000;
        const intervalDelay = 1000;
        let elapsedTime = 0;
        let canRunCheckCoupon = await this.checkIfOrderCompleate();
    
        if (!canRunCheckCoupon) {
            this.setState({ thanksModalIsOpen: true });
            return;
        }
    
        const intervalId = setInterval(async () => {
            elapsedTime += intervalDelay;
    
            canRunCheckCoupon = await this.checkIfOrderCompleate();
    
            if (!canRunCheckCoupon || elapsedTime >= checkDuration) {
                clearInterval(intervalId);
                if (canRunCheckCoupon) {
                    this.checkCoupon();
                }
            }
        }, intervalDelay);
    }

    checkIfOrderCompleate = async () => {
        const { creditUniqueId, checkPreorderResp, clearReadyToOrderCartItems, clearBlockClosing, clearCoupon, setCreditUniqueId } = this.props.myShopApiStore;
        let answer = 1;
        if(creditUniqueId) {
            const resp = await checkPreorderResp();
            if(resp) {
                switch(resp.data.errorCode) {
                    case '0':
                        answer = 0;
                        setCreditUniqueId('');
                        clearReadyToOrderCartItems();
                        clearBlockClosing();
                        clearCoupon();
                        this.coupons = [];
                        this.setState({apiLoaderIsOpen: false});
                        this.setState({ thanksModalIsOpen: true });
                        break;
                    default:
                        break;
                }
            }
        }
        return answer;
    }

    checkCoupon = async () => {
        const { checkCouponResp, shop, getCoupon, clearCoupon, calcTotalCartPrice } = this.props.myShopApiStore;
        const coupon = shop.withCoupons ? await getCoupon() : null;
        if(!coupon) return;
        
        const resp = await checkCouponResp(coupon?.code);
        if(resp.errorCode !== '0') {
            clearCoupon();
            calcTotalCartPrice();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { orderData } = this.props.myShopApiStore;
        const isShippingOption = !orderData || orderData.isDelivery || orderData.orderType === 'delivery';

        if(this.state.isShippingOption !== isShippingOption){
            this.setState({...prevState, isShippingOption})
        }

        if (this.state.orderStatus === 'success' && prevState.orderStatus !== 'success') {
            this.setState(prevState => ({ ...prevState, thanksModalIsOpen: true }));
        }
    }

    isUserRegistred = async () => {
        const { registerUser } = this.props.myShopApiStore;
        this.setState({apiLoaderIsOpen: true});
        const resp = await registerUser();
        this.setState({apiLoaderIsOpen: false});
        !resp?.data && this.setState(prevState => ({ ...prevState, smsModalIsOpen: true }))
        return !!resp?.data;
    }

    isPreOrderCreated = async () => {
        const { createSessionResp, makePreorderResp, creditUniqueId, shop, otherPaymentType, setCreditUniqueId} = this.props.myShopApiStore;

        this.setState({apiLoaderIsOpen: true});
        const resp = await makePreorderResp();
        this.setState({apiLoaderIsOpen: false});

        if(resp.data.errorCode !== '0') {
            this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code: resp.data?.errorCode, message: resp.data?.errorMessage}});
        }
        
        switch (true) {
            case (otherPaymentType == 'cash' && resp.data.errorCode !== '0' && resp.data && resp.data.data && resp.data.data.UniqueId) :
                setCreditUniqueId(resp.data.data.UniqueId);
                return true;
            case !resp.data || resp.data.errorCode !== '0':
                this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code: resp.data?.errorCode, message: resp.data?.errorMessage}})
                return false;
            case shop.paymentSystem === 'stripe' && otherPaymentType == 'cash':
                return true;
            case shop.paymentSystem === 'stripe' && !this.props.myShopApiStore.creditParams.token:
                this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code: 'STRIPE_ERROR', message: 'Server error!'}})
                return false;
            default:
                if(![ 'stripe', 'pelecard' ].includes( shop.paymentSystem) && !['comax'].includes(shop.externalTypeCodename)) {
                    await createSessionResp(creditUniqueId); //only for zcredit
                }
                return true;
        }
    }

    isPreOrderUsed = async (tries) => {
        if (tries <= 0) return false;
        return (await this.props.myShopApiStore.checkPreorderResp())?.data?.errorCode === '0' ||
                (new Promise(resolve => setTimeout(() => resolve(), 3000)))
                    .then(()=> this.isPreOrderUsed(tries-1));
    }

    isMakeOrderSuccess = async () => {
        const { makeOrderResp, orderData, shop, checkMultipassResp, otherPaymentType } = this.props.myShopApiStore;

        this.setState({apiLoaderIsOpen: true});
        const tries = 999999;
        const resp = !orderData.isCredit || shop.paymentSystem !== 'stripe' || (await this.isPreOrderUsed(tries)) 
            ? 
                otherPaymentType == 'multipass'
                ?
                await checkMultipassResp() 
                :
                await makeOrderResp() 
            : { 
                data: {
                    errorCode: 'PaymentError',
                    errorMessage: 'Payment is pending. Try reload the page or click continue one more time later.'
                }
            };

        this.setState({apiLoaderIsOpen: false});

        if(resp.data && resp.data.errorCode == '0' && resp.data.multipass_status === true){
            this.setState({
                multipassEnterAmountModalIsOpen: true,
                multipassEnterAmountModalDetails: {details: resp.data},
                orderStatus: 'failure'
            })
            return false;
        }
        else if (!resp.data || resp.data.errorCode !== '0'){
            this.setState({
                makeOrderErrorModalIsOpen: true, 
                makeOrderErrorModal: {code: resp.data?.errorCode, message: resp.data?.errorMessage},
                orderStatus: 'failure'
            })
            return false;
        }
        else {
            this.setState({orderStatus: 'success'});
            if (process.env.REACT_APP_FACEBOOK_PIXEL) ReactPixel.track('Purchase');
        }

        return true;
    }

    render() {
        const { shopData, shop, cartPrice, hasPostCoupon, orderData, creditUniqueId } = this.props.myShopApiStore; 
        const { currencyName } = shopData.country;
        const { t } = this.props;

        const View = this.checkoutProcess
            .filter((step, i) => step.view && i <= this.state.currentStep )
            .pop().view;  
        return (
            
            <div className={style.Container}>
                {this.state.currentStep == 5 && (
                    <div className={style.WhiteScreen}></div>
                )}
                <div className={style.PriceInfo}>
                {!shop.isBusiness && (
                    ['summary', 'cardNumber'].includes(this.checkoutProcess[this.state.currentStep].name) ? (
                        <div className={style.PriceTitle}>
                            {t('Summary of order and payment')}
                        </div>
                    ) : (this.checkoutProcess[this.state.currentStep].name === 'shipping' && (process?.env.REACT_APP_DONATION ?? 'false') === 'true') ? (
                        <div className={style.PriceTitle}>
                            <div>{t('We made sure you were as comfortable as possible donation')}</div>
                        </div> 
                    ) : (this.checkoutProcess[this.state.currentStep].name === 'shipping') ? (
                        <div className={style.PriceTitle}>
                            <div>{t('We made sure you were as comfortable as possible')}</div>
                            <div>{t('How would you like to receive an order?')}</div>
                        </div> 
                    ) : this.checkoutProcess[this.state.currentStep].name === 'payment_options' ? (
                        <div className={style.PriceTitle}>
                            <div>{(process?.env.REACT_APP_DONATION ?? 'false') === 'true' ? t("Please fill in the recipient's details. donation") : t("Please fill in the recipient's details.")}</div>
                        </div> 
                    ) : (
                        <>
                            <div className={style.PriceTitle}>
                                {t(['summary', 'cardNumber'].includes(this.checkoutProcess[this.state.currentStep].name) 
                                    ? 'total amount' 
                                    : 'Items subtotal') + ' '}
                                <NumberFormat
                                    value={cartPrice}
                                    prefix={currencyName}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                />
                            </div>
                            <div className={style.PriceDescription}> 
                                {(['summary', 'cardNumber'].includes(this.checkoutProcess[this.state.currentStep].name) 
                                    ? t('including taxes (if applicable)') 
                                    : shopData.paymentDescription) + ' '}
                                <NumberFormat
                                    value={cartPrice + (orderData.taxes ?? 0)}
                                    prefix={currencyName}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                />
                            </div>
                        </>
                    )
                )}
                </div>

                <Suspense fallback={<div>Loading</div>}>
                    <View  setShippingOption={ v => this.setState({isShippingOption: v}) }
                        prevStep={this.prevStep}
                        currentStep={this.state.currentStep}
                        nextStep={this.nextStep}
                        openBasket={this.props.openBasket}
                        showLoader = { open => this.setState({apiLoaderIsOpen: open})}
                        showError = {(code, message) => this.setState({makeOrderErrorModalIsOpen: true, makeOrderErrorModal: { code, message}})}
                        tryAnotherCard = {this.tryAnotherCard}
                        updateOrderStatus={this.updateOrderStatus}
                    />
                </Suspense>
                <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                <ValidSMSModal modalIsOpen={this.state.smsModalIsOpen} 
                    closeModal={() => this.setState({smsModalIsOpen: false})}
                    handleMakeOrderCall={this.handleMakeOrderCall} action='Delivery' nextStep={this.nextStep}
                />
                
                <CardNumberModal closeModal={() => this.setState({ cardNumberModalIsOpen: false })}
                    modalIsOpen={ this.state.cardNumberModalIsOpen }
                    nextStep={ this.nextStep }
                    handleSendClick={ () => {
                        this.props.myShopApiStore.setOrderData("isCredit", false);
                        this.props.myShopApiStore.setPaymentProcessing(true);
                        this.checkoutProcess.find(step => step.name === 'summary').nextName()
                    }}
                />

                <MultipassEnterAmountModal 
                    closeModal={() => this.setState({ multipassEnterAmountModalIsOpen: false })}
                    modalIsOpen={ this.state.multipassEnterAmountModalIsOpen }
                    nextStep={ this.nextStep }
                    details={this.state.multipassEnterAmountModalDetails?.details || {}}
                    showLoader = { open => this.setState({apiLoaderIsOpen: open})}
                    handleSendClick={ (value) => {
                        this.props.myShopApiStore.setOrderData("isCredit", false);
                        this.props.myShopApiStore.setPaymentProcessing(false);
                    }}
                />
               
                <MapModal openBasket={this.props.openBasket} 
                    closeModal={() => {
                        this.setState({mapModalIsOpen: false}); 
                        //this.prevStep();
                    }} 
                    modalIsOpen={this.state.mapModalIsOpen} 
                    prevStep={this.prevStep}
                    nextStep={this.nextStep}
                    showLoader={(open) => this.setState({apiLoaderIsOpen: open})}
                    apiLoaderIsOpen={this.state.apiLoaderIsOpen}
                />
                {
                    hasPostCoupon
                    ?
                        <ThanksCouponModal closeModal={() => {
                            this.setState({thanksModalIsOpen: false})
                            this.stopProcessing();
                        }} modalIsOpen={this.state.thanksModalIsOpen} />
                    :
                        <ThanksModal closeModal={() => {
                            this.setState({thanksModalIsOpen: false})
                            this.stopProcessing();
                        }} modalIsOpen={this.state.thanksModalIsOpen} />
                }
                <MakeOrderErrorModal 
                    modalGoBack={false}
                    closeModal={() => {
                            this.setState({makeOrderErrorModalIsOpen: false, makeOrderErrorModal: { code: '', message: ''}})
                            this.stopProcessing();
                            //this.nextStep()
                        }
                    } 
                    modalIsOpen={this.state.makeOrderErrorModalIsOpen} 
                    errorCode={this.state.makeOrderErrorModal.code} 
                    errorMessage={this.state.makeOrderErrorModal.message}
                    openBasket={this.props.openBasket}
                    closeModalContinue={() => {
                        this.setState({makeOrderErrorModalIsOpen: false})
                    }} 
                />
            </div>
        )
    }
}

export default withTranslation()(Payment);
