import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class UnitTypes extends React.Component {
    handleClick = (unitType) => {
        const { removeCartItem, thisCart, addToCart } = this.props.myShopApiStore;
        const { item } = this.props;

        const hasBaseToppings = item.shopBaseToppings?.length > 0;
        const hasToppings = item.shopToppings?.length > 0;
        const hasProductOptions = item.shopProductOptions?.length > 0;

        const hasMult = hasBaseToppings || hasToppings || hasProductOptions;

        console.log(item);

        if(!hasMult) {
            let index = -1;
            for(let i=0; i<thisCart.cartItemList.length ; i++){
                if(thisCart.cartItemList[i].item.id === item.id){
                    index = i;
                    break;
                }
            }

            if(unitType.type !== this.props.selected && index !== -1){
                removeCartItem(index, hasMult);
                addToCart(item, unitType)
            }
        }

        this.props.setUnitTypeSelected(unitType);

        //this.setState({unitSelected: unitType.type})
    }

    render() {
        const { unitTypes, t } = this.props;
        return (
            <div className={style.Container}>
                <div className={style.UnitTypes}>
                {
                    unitTypes.map((unitType, unitIdx) => {
                        return(
                            <div className={this.props.selected === unitType.type ? style.UnitSel : style.UnitNotSel} key={unitIdx} onClick={() => this.handleClick(unitType)}>
                                { t(unitType.type) }
                            </div>
                        )
                    })
                }
                </div>
            </div>
        )
    }
}

export default withTranslation()(UnitTypes);
