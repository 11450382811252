import React, { useRef, useEffect, useState, useMemo } from 'react';
import  { SlArrowUp, SlArrowDown, SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import style from './style.module.css';

const Swing = ({ children, isHorisontal }) => {
    const listRef = useRef();
    const [scroll, setScroll] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);

    const iOS = useMemo(() => /(iPad|iPhone|iPod|Macintosh)/g.test(navigator.userAgent), []);
    const shift = 60;

    const leftScrollOniOS = (scrollWidth, scrollLeft) => { 
        const position = scrollWidth/2 - scrollLeft - shift*4;
        return position > 0 ? position : 0;
    }

    useEffect(() => {
        const { current } = listRef;
        current?.addEventListener('scroll', () => { 
            const { scrollHeight, clientHeight, scrollTop, scrollWidth, clientWidth, scrollLeft } = current;
            const scroll = isHorisontal && iOS ? (scrollLeft > 0 ? leftScrollOniOS(scrollWidth, scrollLeft) : (scrollLeft * 2 - shift*3) ): scrollLeft;            
            setScroll(Math.abs(isHorisontal ? scrollLeft : scrollTop));
            setMaxScroll((isHorisontal ? (scrollWidth - clientWidth) : (scrollHeight - clientHeight)) - 5);
        });

        return () => current?.removeEventListener('scroll', () => {});
    }, [isHorisontal, iOS]);

    

    const isStartOut = scroll <= 5;
    const isEndOut = scroll > maxScroll;

    return (
        <>
            <nav className={style.NavArrow} 
                style={{ 
                    [isHorisontal ? 'right': 'top']: '10px', 
                    display: isStartOut ? 'none' : 'flex'
                }} 
                onClick={() => isHorisontal ? listRef.current.scrollBy(shift, 0) : listRef.current.scrollBy(0, -shift)}>
                   { isHorisontal ? <SlArrowRight title={scroll}/> : <SlArrowUp /> }
            </nav>
                {children(listRef)}
            <nav className={style.NavArrow} style={{ 
                    [ isHorisontal ? 'left': 'bottom']: '10px', 
                    display: isEndOut ? 'none' : 'flex'
                }} 
                onClick={() =>  isHorisontal ? listRef.current.scrollBy(-shift, 0) : listRef.current.scrollBy(0, shift)} 
                    >
                    { isHorisontal ? <SlArrowLeft title={maxScroll}/> : <SlArrowDown/> }
            </nav>
        </>
    )
}

export default Swing;