import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import WorkTime from './WorkTime';
import { withTranslation } from 'react-i18next';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})
@observer
class Contacts extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className={style.container}>
                <div className={style.Header}>{this.props.myShopApiStore.my_env.url.indexOf("falafelzikhron") >= 0 ? t('Contacts') /*t('WorkingMode')*/ : t('Contacts')}</div>
                <div className={style.Contacts}>
                    <div className={style.ContactsName}>{this.props.myShopApiStore.getShopName()} - {this.props.myShopApiStore.getShopAddress()}</div>
                    <div className={style.ContactsPhone}> {t('PhoneNumber')} <a href={`tel:${this.props.myShopApiStore.getShopPhone2()}`}>{this.props.myShopApiStore.getShopPhone2()}</a></div>
                    {
                        this.props.myShopApiStore.getShopWorkTimes()? 
                            <WorkTime items={this.props.myShopApiStore.getShopWorkTimes()}/>
                        : ''
                    }
                    <div className={style.ContactsDesc}>{(process?.env.REACT_APP_DONATION ?? 'false') === 'true' ? t('InCaseAnyQuestionDonationPleaseContact') : t('InCaseAnyQuestionPleaseContact')}</div>
                    {/* <div className={style.ContactsEmail}><a href={`mailto:${this.props.myShopApiStore.getShopContactEmail()}`}>{this.props.myShopApiStore.getShopContactEmail()}</a></div> */}
                </div>
            </div>
        )
    }
}
export default withTranslation()(Contacts);
